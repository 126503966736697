import {objectMapValues} from "./object-utils";
import {stringConvertJsIdentifierToDashCase} from "./string-utils";

export interface UiPartGroupDefinition<GROUP_NAME extends string> {
    __isUiPartGroup: true;
    groupName: GROUP_NAME;
}

const defaultUiPartController = uiPartCreateCustomController({
    nameAttributePrefix: 'data-ui-part-',
    groupNameAttributePrefix: 'data-ui-parts-group-'
});

export const uiPartCreateMarking = defaultUiPartController.uiPartCreateMarking;
export const uiPartCreateSelector = defaultUiPartController.uiPartCreateSelector;
export const uiPartsGroupCreateMarking = defaultUiPartController.uiPartsGroupCreateMarking;
export const uiPartsGroupCreateSelector = defaultUiPartController.uiPartsGroupCreateSelector;

export function uiPartCreateNamespacedParts<T extends Record<string, string>> (
    namespace: string,
    parts: T
) {
    return objectMapValues(parts, partName => `${namespace}-${partName}`.toLowerCase());
}

export function uiPartCreateNamespacedPartsGroups<T extends Record<string, string>> (
    namespace: string,
    groups: T
) {
    return uiPartDefineGroups(objectMapValues(groups, partName => `${namespace}-${partName}`.toLowerCase()));
}

export function uiPartDefineGroups<T extends Record<any, string>> (
    groups: T
) : {
    [key in keyof T]: UiPartGroupDefinition<T[key]>
} {
    return objectMapValues(groups, value => {
        return {
            __isUiPartGroup: true,
            groupName: value
        }
    }) as any;
}

export function uiPartCreateCustomController(
    options: {
        nameAttributePrefix: string;
        groupNameAttributePrefix: string;
    }
) {
    const {
        nameAttributePrefix,
        groupNameAttributePrefix
    } = options;

    const normalizePartName = (partName: string | number) => {
        return stringConvertJsIdentifierToDashCase(`${partName}`);
    }
    
    const defaultDistinguishingId = '__default';
    
    return {
        uiPartCreateMarking: (partName: string | number, distinguishingId?: string | number) => {
            return {
                [`${nameAttributePrefix}${normalizePartName(partName)}`]: distinguishingId ?? defaultDistinguishingId
            }
        },
        uiPartCreateSelector: (partName: string | number, distinguishingId?: string | number) => {
            return `[${nameAttributePrefix}${normalizePartName(partName)}="${distinguishingId ?? defaultDistinguishingId}"]`;
        },
        uiPartsGroupCreateMarking: (groupDefinition: UiPartGroupDefinition<any>, partId?: string) => {
            return {
                [`${groupNameAttributePrefix}${groupDefinition.groupName}`]: partId ?? 'true'
            }
        },
        uiPartsGroupCreateSelector: (groupDefinition: UiPartGroupDefinition<any>, partId?: string) => {

            if (partId) {
                return `[${groupNameAttributePrefix}${groupDefinition.groupName}="${partId}"]`
            } else {
                return `[${groupNameAttributePrefix}${groupDefinition.groupName}]`
            }
        },
    };
}
