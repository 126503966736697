import {
    arrayRemoveNullValues,
    CurrencyCode,
    currencyFormatPrice,
    currencyGetAllCodes,
    ObjectFieldsAssertion,
    urlJoin
} from '@wix/devzai-utils-common';
import {WixProduct, wixProductMediaTryConvertToWixMediaResource} from '@wix/devzai-common-wix';
import {DoppeActionPriceData} from './doppe-action-price-data';

export interface DoppeDtoStoreProduct {
    id: string;
    name: string;
    description: string;
    descriptionPlainText: string;
    media?: WixProduct.Media;
    price: DoppeDtoStoreProduct.PriceData;
    productPageUrl?: string;
    discount?: WixProduct.Discount;
    stock?: WixProduct.Stock;
    productType: WixProduct.ProductType;
}

export namespace DoppeDtoStoreProduct {
    export type Id = DoppeDtoStoreProduct['id'];

    export type PriceData = WixProduct.PriceData;

    export type ForUpdate = Pick<DoppeDtoStoreProduct, 'id' | 'price' | 'name' | 'descriptionPlainText' | 'description' | 'media' | 'productType'>

    export type ForCreation = Pick<DoppeDtoStoreProduct, 'price' | 'name'| 'descriptionPlainText' | 'description' | 'media' | 'productType'>
}

export function doppeStoreProductResolveUrl(productPageUrl: WixProduct.PageUrl) {
    if (productPageUrl?.base && productPageUrl?.path) {
        return urlJoin(productPageUrl.base,productPageUrl.path);
    }

    return undefined;
}

export const DoppeDtoStoreProductForCreationFieldsAssertions: ObjectFieldsAssertion<DoppeDtoStoreProduct.ForCreation> = {
    name: assert => assert.isString(),
    description: assert => assert.isString(),
    descriptionPlainText: assert => assert.isString(),
    price: assert => assert.usingAssertionFunction(() => {
        // TODO
    }),
    media: assert => assert.optional.usingAssertionFunction(() => {
        // TODO
    }),
    productType: assert => assert.usingAssertionFunction(() => {
        // TODO
    }),
}

export const DoppeDtoStoreProductForUpdateFieldsAssertions: ObjectFieldsAssertion<DoppeDtoStoreProduct.ForUpdate> = {
    id: assert => assert.isString(),
    name: assert => assert.isString(),
    description: assert => assert.isString(),
    descriptionPlainText: assert => assert.isString(),
    price: assert => assert.usingAssertionFunction(() => {
        // TODO
    }),
    media: assert => assert.optional.usingAssertionFunction(() => {
        // TODO
    }),
    productType: assert => assert.usingAssertionFunction(() => {
        // TODO
    }),
}

export function doppeDtoStoreProductPriceDataConvertToActionPriceData (price: DoppeDtoStoreProduct.PriceData) : DoppeActionPriceData {
    const actualPrice = doppeDtoStoreProductConvertToFormattedPrice(price, '');
    const discountPrice = doppeDtoStoreProductConvertToFormattedDiscountPrice(price, '');

    return {
        actualPrice: actualPrice,
        discountPrice: actualPrice !== discountPrice ? discountPrice : ''
    }
}

export function doppeDtoProductGetMedia (dtoStoreProduct: DoppeDtoStoreProduct) {
    return arrayRemoveNullValues([
        dtoStoreProduct.media?.mainMedia ? wixProductMediaTryConvertToWixMediaResource(dtoStoreProduct.media?.mainMedia) : null,
    ])
}

export function doppeDtoStoreProductConvertToFormattedPrice<EmptyValue>(priceData: DoppeDtoStoreProduct.PriceData, emptyValue: EmptyValue): string | EmptyValue {
    const currency = priceData.currency as CurrencyCode;

    return priceData.price && (priceData.currency && currencyGetAllCodes().includes(currency)) ? currencyFormatPrice({
        code: currency,
        price: priceData.price
    }) : (priceData.formatted?.price ?? emptyValue)
}

export function doppeDtoStoreProductConvertToFormattedDiscountPrice<EmptyValue>(priceData: DoppeDtoStoreProduct.PriceData, emptyValue: EmptyValue): string | EmptyValue {
    const currency = priceData.currency as CurrencyCode;

    return priceData.discountedPrice && (priceData.currency && currencyGetAllCodes().includes(currency)) ? currencyFormatPrice({
        code: currency,
        price: priceData.discountedPrice
    }) : (priceData.formatted?.discountedPrice ?? emptyValue)
}