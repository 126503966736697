import {AutoValue, autoValueCreate, autoValueIsAutoValue, Values} from '@wix/devzai-utils-common';
import {doppeActionTypeDefineMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {
    DoppeHideableValue,
    doppeHideableValueCreateHidden
} from '../../client-server-common/types/doppe-hideable-value';
import {WixMediaResource} from '@wix/devzai-common-wix';
import {RichTextContent} from '@wix/devzai-utils-react';
import {
    doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction
} from '../../client-server-common/types/doppe-dto-action';

export const YoutubeEmbedType = {
    VideoUrl: 'VideoUrl',
    LatestVideo: 'LatestVideo',
} as const;


export namespace DoppeActionTypeYoutubeVideo {

    export type YoutubeEmbedType = Values<typeof YoutubeEmbedType>;

    export const DoppeActionTypeYoutubeVideoURLAutoSource = {
        SameAsVideoURL: 'SameAsVideoURL',
    } as const;

    export type DoppeActionTypeYoutubeVideoURLAutoSource = Values<typeof DoppeActionTypeYoutubeVideoURLAutoSource>;
    export type DoppeActionTypeYoutubeVideoURLSource = AutoValue<DoppeActionTypeYoutubeVideoURLAutoSource> | string;

    export interface ActionSettings {
        url: string;
        type: YoutubeEmbedType;
        title: DoppeHideableValue<string>;
        description: DoppeHideableValue<RichTextContent>;
        videoButtonText: DoppeHideableValue<string>;
        videoPlayerSettings: {
            autoplay: boolean;
            muteVideo: boolean;
        },
        videoImage: WixMediaResource | null;
        videoButtonURL: DoppeActionTypeYoutubeVideoURLSource,
    }
}

export const doppeActionTypeYoutubeVideo = doppeActionTypeDefineMetadata<DoppeActionTypeYoutubeVideo.ActionSettings>({
    id: 'io.bymo.action/youtube-video',
    resolveActionSettings: action => {

        return {
            url: '',
            type: YoutubeEmbedType.VideoUrl,
            videoPlayerSettings: {
                autoplay: true,
                muteVideo: false
            },
            title: doppeHideableValueCreateHidden(''),
            description: doppeHideableValueCreateHidden(''),
            videoButtonText: doppeHideableValueCreateHidden(''),
            videoImage: null,
            videoButtonURL: autoValueCreate(DoppeActionTypeYoutubeVideo.DoppeActionTypeYoutubeVideoURLAutoSource.SameAsVideoURL),
            ...action.settings
        }
    },
    prepareForViewer: (actionSettings, options) => {
        doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction(actionSettings, actionSettings => {
            if (!autoValueIsAutoValue(actionSettings.videoButtonURL)) {
                actionSettings.videoButtonURL = '';
            }
        }, options);
    },
    resolveMainLink: actionSettings => {
        return actionSettings.url;
    },
    isWidgetOpener: true,
    isLinkOpener: true,
    canRenderInPage: true,
})

