import React, {useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState} from 'react';
import {classes, vars} from './bymo-page-viewer.st.css';
import {BymoPageContextProps, BymoPageContextPropsContext} from '../../doppe-sdk';
import {
    BymoPageProps,
    BymoPagePropsContext,
    BymoPagePropsForViewer,
    bymoPagePropsResolveTextFontFamilyStyleSheetUrl,
    DoppeBiEmptyTargetUrl
} from '../../client-server-common';
import {
    BymoPageViewerMode,
    bymoPageViewerModeIsPreview,
    useBymoPageViewerContext
} from '../bymo-page-viewer-context/bymo-page-viewer-context';
import {useDoppeViewerClientServices} from '../../client/doppe-user-app/doppe-viewer-client-services';
import {
    ListViewIdsProvider,
    ModalsScope,
    reactProvideContextValues,
    SsrSafeHtmlIdsProvider,
    useLayoutBinding,
    useObservableValue,
    useRootWindow,
    useWindowDependantMemoWithDefaultValue
} from "@wix/devzai-utils-react";
import {DomEventListener, domGetScrollbarSize, domLoadCSS} from "@wix/devzai-utils-dom";
import {arrayLast, immerProduce, objectGetEntries, referencesEqualityComparer} from "@wix/devzai-utils-common";
import {BymoPageStyleContext, bymoPageStyleResolveVars} from "../bymo-page-style/bymo-page-style";
import {BrowserHistory} from "@wix/devzai-common-client";
import {
    DoppeViewerColorPaletteCalculatedColorsDataReactContext, DoppeViewerLangReactContext
} from "../doppe-viewer-bymo-page-props-react/doppe-viewer-bymo-page-props-react";

const Features = {
    enableBackInterception: true
}

export const BymoPageViewer = React.memo(function BymoPageViewer(props: BymoPageViewer.Props) {
    const {
        bymoPageComponentType,
        bymoPagePropsForViewer,
        bymoPageContextProps
    } = props;

    const pageViewerContext = useBymoPageViewerContext();
    const clientServices = useDoppeViewerClientServices();

    useEffect(() => {
        if (pageViewerContext.mode === BymoPageViewerMode.Live) {
            clientServices.viewerAnalyticsClient.logEvent('uouPageViewed', {
                pageId: pageViewerContext.bymoPageId,
                hypeUserId: pageViewerContext.doppeUserId,
                linkId: pageViewerContext.linkId,
                destinationUrl: bymoPageContextProps.targetUrl ?? DoppeBiEmptyTargetUrl,
                pageType: pageViewerContext.pageType
            });
        }
    }, []);

    const rootWindow = useRootWindow();
    const modalsScopeRef = useRef<ModalsScope>(null);

    useLayoutEffect(() => {
        if (Features.enableBackInterception && pageViewerContext.mode !== BymoPageViewerMode.LivePreview) {
            const disposeBrowserHistoryInterception = BrowserHistory.interceptNavigation((_, action) => {

                if (action === 'POP') {
                    const topMostModal = arrayLast(modalsScopeRef.current?.getOpenModals() ?? []);

                    if (topMostModal) {
                        return BrowserHistory.interceptionAction(() => {
                            topMostModal.closeWindow();
                        });
                    }
                }

                return false;
            }, Infinity)

            return () => {
                disposeBrowserHistoryInterception();
            }
        } else {
            return undefined;
        }
    }, []);

    const actionsSettingsOverrides = useObservableValue(clientServices.bymoService.observableActionsSettingsOverrides);

    const bymoPageProps = useMemo(() => {
        return immerProduce(bymoPagePropsForViewer, bymoPagePropsForViewer => {
            objectGetEntries(actionsSettingsOverrides).forEach(([actionId, actionSettings]) => {
                const action = bymoPagePropsForViewer.actions.find(action => action.id === actionId);

                if (action) {
                    action.settings = actionSettings;
                }
            });
        })
    }, [bymoPagePropsForViewer, actionsSettingsOverrides]);

    const textFontFamilyStyleSheetUrl = useMemo(() => {
        return bymoPagePropsResolveTextFontFamilyStyleSheetUrl(bymoPageProps);
    }, [bymoPageProps]);

    useEffect(() => {
        if (pageViewerContext.mode !== BymoPageViewerMode.Live) {
            domLoadCSS(textFontFamilyStyleSheetUrl, {rootWindow: rootWindow})
                .catch(error => {
                    console.error(`Failed loading font css`, error)
                })
        }
    }, [textFontFamilyStyleSheetUrl])

    const hideBrowserScrollbar = bymoPageViewerModeIsPreview(pageViewerContext.mode);
    useEffect(() => {
        const currentWindow = (rootWindow ?? window);

        currentWindow.document.documentElement.classList.toggle(
            classes.hideBrowserScrollbar,
            hideBrowserScrollbar || (currentWindow.self !== currentWindow.top)
        );

    }, [hideBrowserScrollbar, rootWindow])

    const bymoPageNamespacedStyleVarsValues = useMemo(() => {
        return bymoPageStyleResolveVars({
            lang: bymoPageProps.lang,
            colorPalette: bymoPageProps.colorPalette,
            textFontFamily: bymoPageProps.textFontFamily,
            devCustomPageContentWidthInWideLayout: bymoPageProps.devCustomPageContentWidthInWideLayout
        })
    }, [
        bymoPageProps.lang,
        bymoPageProps.colorPalette,
        bymoPageProps.textFontFamily,
        bymoPageProps.devCustomPageContentWidthInWideLayout
    ]);

    const lastScrollTopRef = useRef<number>(0);

    const isIos = useWindowDependantMemoWithDefaultValue(
        window => /iPhone|iPad|iPod/.test(window.navigator.userAgent) || true,
        false,
        []
    );

    const [modalIsShown, setModalIsShown] = useState(false);

    const visualViewportHeight = useLayoutBinding(
        useCallback(() => (rootWindow ?? window).visualViewport?.height, [rootWindow]),
        referencesEqualityComparer,
        isIos && modalIsShown
    );

    const isModalShownOnIos = isIos && modalIsShown;

    useEffect(() => {
        if (isModalShownOnIos) {
            const targetWindow = rootWindow ?? window;

            let ignoreScrolling = false;
            const touchStartBinding = DomEventListener.bind(targetWindow.document, 'touchstart', () => {
                ignoreScrolling = true;
            })

            let scheduledScroll: any | undefined = undefined;
            const scheduleScroll = () => {
                clearTimeout(scheduledScroll);
                scheduledScroll = setTimeout(() => {
                    targetWindow.scrollTo({
                        top: 0
                    })
                }, 16)
            }


            const touchEndBinding = DomEventListener.bind(targetWindow.document, 'touchend', () => {
                ignoreScrolling = false;
                scheduleScroll();
            })

            const binding = DomEventListener.bind(targetWindow, 'scroll', () => {
                if (!ignoreScrolling) {
                    scheduleScroll();
                }
            })

            return () => {
                clearTimeout(scheduledScroll);
                binding.dispose();
                touchStartBinding.dispose();
                touchEndBinding.dispose();
            }
        } else {
            return undefined;
        }
    }, [isModalShownOnIos, rootWindow])

    useLayoutEffect(() => {
        (rootWindow ?? window).document.documentElement.style.setProperty(
            vars.visualViewportHeight,
            isIos && visualViewportHeight ? `${visualViewportHeight}px` : `100%`
        );
    }, [visualViewportHeight, isIos])

    return reactProvideContextValues()
        .addContextValue(BymoPageStyleContext, bymoPageNamespacedStyleVarsValues)
        .addContextValue(BymoPageContextPropsContext, bymoPageContextProps)
        .addContextValue(BymoPagePropsContext, bymoPageProps)
        .addContextValue(DoppeViewerLangReactContext, bymoPageProps.lang)
        .addContextValue(DoppeViewerColorPaletteCalculatedColorsDataReactContext, bymoPageProps.colorPaletteCalculatedColorsData)
        .render(
            <ListViewIdsProvider>
                <SsrSafeHtmlIdsProvider>
                    <ModalsScope
                        ref={modalsScopeRef}
                        targetContainer={() => (rootWindow ?? window).document.body}
                        zIndex={999}
                        modalsClassName={classes.modalView}
                        onOpenModalsChange={(openModals) => {

                            const currentWindow = (rootWindow ?? window);
                            const document = currentWindow.document;
                            const siteMain = document.getElementById('SITE_MAIN');

                            if (openModals.length > 0) {
                                const lastScrollTop = lastScrollTopRef.current = document.documentElement.scrollTop;

                                document.documentElement.style.setProperty(vars.scrollbarSize, `${domGetScrollbarSize()}px`);
                                document.documentElement.style.setProperty(vars.verticalShift, `-${lastScrollTop}px`);
                                document.documentElement.classList.toggle(classes.modalShown, true);

                                if (siteMain) {
                                    siteMain.scrollTop = lastScrollTop;
                                }

                                if (isIos) {
                                    const visualViewportHeight = currentWindow.visualViewport?.height
                                    document.documentElement.style.setProperty(
                                        vars.visualViewportHeight,
                                        isIos && visualViewportHeight ? `${visualViewportHeight}px` : `100%`
                                    )
                                }

                                setModalIsShown(true);
                            } else {

                                const restoreScrolling = () => {
                                    if (siteMain) {
                                        siteMain.scrollTop = 0;
                                    }

                                    document.documentElement.classList.toggle(classes.modalShown, false);
                                    document.documentElement.scrollTop = lastScrollTopRef.current;
                                    setModalIsShown(false);
                                }

                                if (Features.enableBackInterception) {
                                    setTimeout(() => {
                                        restoreScrolling();
                                    }, 100)
                                } else {
                                    restoreScrolling();
                                }
                            }

                            // document.documentElement.style.setProperty(vars.scrollbarSize, `${domGetScrollbarSize()}px`);
                            // document.documentElement.classList.toggle(classes.modalsShown, openModals.find(modalInfo => modalInfo.isFullScreen) !== undefined);
                        }}
                    >
                        {React.createElement(bymoPageComponentType, bymoPageProps)}
                    </ModalsScope>
                </SsrSafeHtmlIdsProvider>
            </ListViewIdsProvider>
        )
});

export namespace BymoPageViewer {

    export interface Props {
        bymoPageComponentType: React.ComponentType<BymoPageProps<any>>;
        bymoPagePropsForViewer: BymoPagePropsForViewer;
        bymoPageContextProps: BymoPageContextProps;
    }
}