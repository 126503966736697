import React, {useContext} from "react";
import {assertNotNullable} from "@wix/devzai-utils-common";
import {DoppeColorPaletteCalculatedColorsData} from "../../doppe-sdk";
import {DoppeViewerLang} from "../../client-server-common/doppe-viewer/doppe-viewer-lang";

export const DoppeViewerColorPaletteCalculatedColorsDataReactContext = React.createContext<DoppeColorPaletteCalculatedColorsData | null>(null);

export function useAssertDoppeViewerColorPaletteCalculatedColorsDataReactContext () {
    return assertNotNullable(useContext(DoppeViewerColorPaletteCalculatedColorsDataReactContext), `DoppeViewerColorPaletteCalculatedColorsDataReactContext is not provided`);
}

export const DoppeViewerLangReactContext = React.createContext<DoppeViewerLang | null>(null);

export function useAssertDoppeViewerLangReactContext() {
    return assertNotNullable(useContext(DoppeViewerLangReactContext), `DoppeViewerLangReactContext is not provided`);
}