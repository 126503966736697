import {doppeActionTypeDefineMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {immerProduce, iterableMapToArray, PartiallyOptional} from '@wix/devzai-utils-common';
import {DoppeActionTypeCoupon} from '../doppe-action-type-coupon/doppe-action-type-coupon';
import {wixMediaResourceReplaceExternalImage} from '@wix/devzai-common-wix';
import {
    DoppeActionLayout,
    DoppeActionListItemClickBehaviour,
    DoppeListActionSettings
} from '../../client-server-common';
import {
    doppeExternalCouponProviderResolveBusinessEntityReference
} from '../../client-server-common/types/doppe-external-coupon';
import {doppeHideableValueCreateHidden} from '../../client-server-common/types/doppe-hideable-value';
import {doppeDtoActionGetDefaultSearchListSettingsProps} from '../../client-server-common/types/doppe-dto-action';
import {DoppeActionTypeCouponsListViewerLang} from './doppe-action-type-coupons-list-viewer.lang';


export namespace DoppeActionTypeCouponsList {

    export interface CouponSettings extends DoppeActionTypeCoupon.ActionSettings {
        id: string;
        title: string;
    }

    export interface ActionSettings extends
        DoppeListActionSettings,
        DoppeListActionSettings.WithItemClickBehaviour,
        DoppeListActionSettings.WithMainDetails {
        coupons: CouponSettings[];
        previewShowTitle: boolean;
        previewShowDescription: boolean;
        previewCouponButtonText: string;
    }
}


export const doppeActionTypeCouponsList = doppeActionTypeDefineMetadata<DoppeActionTypeCouponsList.ActionSettings>({
    id: 'io.bymo.action/coupons-list',
    resolveActionSettings: action => {

        const {
            coupons = [],
            ...restSettings
        } = action.settings;

        return {
            coupons: coupons.map(coupon => {
                return {
                    couponProvider: null,
                    couponProviderId: null,
                    ribbon: doppeHideableValueCreateHidden(''),
                    ...coupon as PartiallyOptional<DoppeActionTypeCouponsList.CouponSettings, 'couponProvider' | 'couponProviderId' | 'ribbon'>
                }
            }),
            layout: DoppeActionLayout.Column,
            showButton: false,
            buttonText: 'See More Coupons',
            buttonUrl: 'https://www.website.com',
            listSearchSettings: doppeDtoActionGetDefaultSearchListSettingsProps(),
            itemShowDescription: true,
            itemShowTitle: true,
            itemShowImage: true,
            itemShowTextOnImage: false,
            previewShowDescription: true,
            previewShowTitle: true,
            previewCouponButtonText: DoppeActionTypeCouponsListViewerLang.defaultTexts.couponButtonText,
            itemClickBehaviour: DoppeActionListItemClickBehaviour.PreviewItem,
            mainImage: doppeHideableValueCreateHidden(null),
            mainTitle: doppeHideableValueCreateHidden('My Coupons'),

            ...restSettings
        }
    },
    isWidgetOpener: true,
    canRenderInPage: true,
    isListItemLinkOpener: false,
    supportsListLayout: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    resolveUsedMedia: actionSettings => {
        return iterableMapToArray(actionSettings.coupons, (coupon, skip) => {
            return coupon.couponImage ?? skip
        });
    },
    resolveMainMedia: actionSettings => {
        return actionSettings.coupons[0]?.couponImage ?? null;
    },
    resolveMainLink: actionSettings => {
        return actionSettings.showButton ? actionSettings.buttonUrl : null;
    },
    resolveListItemsCount: actionSettings => {
        return actionSettings.coupons.length;
    },
    resolveListItems: action => {
        const actionSettings = action.settings;
        return actionSettings.coupons.map(item => {
            return {
                title: item.title,
                action: action,
                couponCode: item.coupon,
                description: item.description,
                image: item.couponImage,
                itemId: item.id,
                ribbon: item.ribbon,
                url: item.couponPageLink
            }
        });
    },
    resolveListSearchSettings: actionSettings => {
        return actionSettings.listSearchSettings;
    },
    resolveUsedBusinessEntities: actionSettings => {
        return iterableMapToArray(actionSettings.coupons, (coupon, skip) => {
            return coupon.couponProvider !== null && coupon.couponProviderId !== null ?
                doppeExternalCouponProviderResolveBusinessEntityReference(
                    coupon.couponProvider,
                    coupon.couponProviderId
                ) : skip
        });
    },
    replaceExternalImages: (actionSettings, externalImagesUrlToImageResourceMap) => {
        return immerProduce(actionSettings, actionSettings => {
            for (const item of actionSettings.coupons) {
                if (item.couponImage) {
                    item.couponImage =
                        wixMediaResourceReplaceExternalImage(item.couponImage, externalImagesUrlToImageResourceMap)
                }
            }
        })
    },
    hasNoLockInnerCTA: true,
});