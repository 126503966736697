import {doppeActionTypeDefineMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {arrayFlatten, immerProduce, iterableMapToArray, PartiallyOptional} from '@wix/devzai-utils-common';
import {wixMediaResourceReplaceExternalImage} from '@wix/devzai-common-wix';
import {
    DoppeActionLayout,
    DoppeActionListItemClickBehaviour,
    doppeExternalMembershipPlanProviderResolveBusinessEntityReference,
    doppeHideableValueCreateHidden,
    DoppeListActionSettings
} from '../../client-server-common';
import {
    convertDoppeWixMembershipPlanToPlanSettings,
    DoppeActionTypeMembership
} from '../doppe-action-type-membership/doppe-action-type-membership';
import {DoppeDtoMembershipPlan} from '../../client-server-common/types/doppe-dto-membership-plan';
import {
    DoppeMembershipPlansExternalDataSource,
    doppeMembershipPlansExternalDataSourceResolveBusinessEntityReferences
} from '../../client-server-common/types/doppe-membership-plans-external-data-source';
import {
    doppeDtoActionGetDefaultSearchListSettingsProps,
    doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction
} from '../../client-server-common/types/doppe-dto-action';


export namespace DoppeActionTypeMembershipList {

    export interface PlanSettings extends DoppeActionTypeMembership.ActionSettings {
        id: string;
        membershipTitle: string;
    }

    export interface ActionSettings extends DoppeListActionSettings,
        DoppeListActionSettings.WithMainDetails,
        DoppeListActionSettings.WithItemClickBehaviour,
        DoppeListActionSettings.WithExternalDataSourceSupport<DoppeMembershipPlansExternalDataSource> {
        plans: PlanSettings[];
        itemShowPrice: boolean;
        previewShowTitle: boolean;
        previewShowDescription: boolean;
        previewShowPrice: boolean;
        previewShowCoupon: boolean;
        previewPlanButtonText: string;
    }
}


export const doppeActionTypeMembershipList = doppeActionTypeDefineMetadata<DoppeActionTypeMembershipList.ActionSettings>({
    id: 'io.bymo.action/membership-list',
    resolveActionSettings: action => {

        const {
            plans = [],
            ...restSettings
        } = action.settings;

        return {
            plans: plans.map(plan => {
                return {
                    membershipProvider: null,
                    membershipProviderId: null,
                    ...plan as PartiallyOptional<DoppeActionTypeMembershipList.PlanSettings, 'membershipProvider' | 'membershipProviderId'>
                }
            }),
            layout: DoppeActionLayout.Swiper,
            showButton: false,
            buttonText: 'Visit My Website',
            buttonUrl: 'https://www.website.com',
            itemClickBehaviour: DoppeActionListItemClickBehaviour.PreviewItem,

            itemShowDescription: true,
            itemShowTitle: true,
            itemShowImage: true,
            itemShowPrice: true,
            itemShowTextOnImage: false,
            previewShowCoupon: true,
            previewShowPrice: true,
            previewShowDescription: true,
            previewShowTitle: true,
            previewPlanButtonText: 'Subscribe Now',

            externalDataSource: null,
            externalDataSourceItemsCount: 4,
            listSearchSettings: doppeDtoActionGetDefaultSearchListSettingsProps(),

            mainImage: doppeHideableValueCreateHidden(null),
            mainTitle: doppeHideableValueCreateHidden('My Plans'),

            ...restSettings
        }
    },
    prepareForViewer: (actionSettings, options) => {
        doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction(actionSettings, actionSettings => {
            actionSettings.plans.forEach(item => {
                item.membershipPageLink = '';
            });
        }, options);
    },
    isWidgetOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    supportsListLayout: true,
    isListItemLinkOpener: true,
    resolveUsedMedia: actionSettings => {
        return arrayFlatten(actionSettings.plans.map(plan => {
            return plan.membershipImages ?? []
        }));
    },
    resolveMainMedia: actionSettings => {
        return actionSettings.plans[0]?.membershipImages ? actionSettings.plans[0]?.membershipImages[0] : null;
    },
    resolveMainLink: actionSettings => {
        return actionSettings.showButton ? actionSettings.buttonUrl : null;
    },
    isConnectedToExternalDataSource: actionSettings => {
        return !!actionSettings.externalDataSource
    },
    resolveUsedBusinessEntities: actionSettings => {
        const externalDataSource = actionSettings.externalDataSource;
        if (externalDataSource) {
            return [
                doppeMembershipPlansExternalDataSourceResolveBusinessEntityReferences(externalDataSource)
            ];
        } else {
            return iterableMapToArray(actionSettings.plans, (plan, skip) => {
                return plan.membershipProvider !== null && plan.membershipProviderId !== null ?
                    doppeExternalMembershipPlanProviderResolveBusinessEntityReference(
                        plan.membershipProvider,
                        plan.membershipProviderId
                    ) : skip
            });
        }
    },
    resolveListItemsCount: actionSettings => {
        return actionSettings.externalDataSource ? actionSettings.externalDataSourceItemsCount : actionSettings.plans.length;
    },
    resolveListItems: action => {
        const actionSettings = action.settings;
        return actionSettings.plans.map(item => {
            return {
                title: item.membershipTitle,
                action: action,
                couponCode: item.couponCode,
                description: item.membershipDescription,
                image: item.membershipImages?.[0] ?? null,
                itemId: item.id,
                ribbon: item.ribbon,
                url: item.membershipPageLink
            }
        });
    },
    resolveListSearchSettings: actionSettings => {
        return actionSettings.listSearchSettings;
    },
    replaceExternalImages: (actionSettings, externalImagesUrlToImageResourceMap) => {
        return immerProduce(actionSettings, actionSettings => {
            for (const plan of actionSettings.plans) {
                if (plan.membershipImages) {
                    plan.membershipImages = plan.membershipImages.map(
                        image => wixMediaResourceReplaceExternalImage(image, externalImagesUrlToImageResourceMap))
                }
            }
        })
    },
});

export function convertDoppeWixMembershipPlanToPlansSettings(
    plan: DoppeDtoMembershipPlan,
    websiteUrl?: string,
): DoppeActionTypeMembershipList.PlanSettings {
    return {
        ...convertDoppeWixMembershipPlanToPlanSettings(plan, websiteUrl),
        id: plan.id,
        membershipTitle: plan?.name ?? '',
        linkButtonText: doppeHideableValueCreateHidden(''),
    }
}