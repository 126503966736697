import {
    DoppeActionType,
    DoppeActionTypeMetadata,
    DoppeActionTypeViewerMetadata
} from '../../../client-server-common/doppe-action-types/doppe-action-type';
import {doppeActionTypeLink} from '../../../doppe-action-types/doppe-action-type-link/doppe-action-type-link';
import {
    doppeActionTypeDebugApi
} from '../../../doppe-action-types/doppe-action-type-debug-api/doppe-action-type-debug-api';
import {doppeActionTypeVideo} from '../../../doppe-action-types/doppe-action-type-video/doppe-action-type-video';
import {doppeActionTypeSkip} from '../../../doppe-action-types/doppe-action-type-skip/doppe-action-type-skip';
import {doppeActionTypePatreon} from '../../../doppe-action-types/doppe-action-type-patreon/doppe-action-type-patreon';
import {doppeActionTypeProduct} from '../../../doppe-action-types/doppe-action-type-product/doppe-action-type-product';
import {
    doppeActionTypeMembership
} from '../../../doppe-action-types/doppe-action-type-membership/doppe-action-type-membership';
import {
    doppeActionTypeSubscribeEmbed
} from '../../../doppe-action-types/doppe-action-type-embed/doppe-action-type-subscribe-embed';
import {doppeActionTypeFollow} from '../../../doppe-action-types/doppe-action-type-follow/doppe-action-type-follow';
import {doppeActionTypeService} from '../../../doppe-action-types/doppe-action-type-service/doppe-action-type-service';
import {
    doppeActionTypeBlogPost
} from '../../../doppe-action-types/doppe-action-type-blog-post/doppe-action-type-blog-post';
import {doppeActionTypeEvent} from '../../../doppe-action-types/doppe-action-type-event/doppe-action-type-event';
import {
    doppeActionTypeSpeedDial
} from '../../../doppe-action-types/doppe-action-type-speed-dial/doppe-action-type-speed-dial';
import {doppeActionTypeShare} from '../../../doppe-action-types/doppe-action-type-share/doppe-action-type-share';
import {doppeActionTypeCoupon} from '../../../doppe-action-types/doppe-action-type-coupon/doppe-action-type-coupon';
import {
    doppeActionTypeSocialIcon
} from '../../../doppe-action-types/doppe-action-type-social-icon/doppe-action-type-social-icon';
import {
    doppeActionTypeProducts
} from '../../../doppe-action-types/doppe-action-type-products/doppe-action-type-products';
import {
    doppeActionTypeCouponsList
} from '../../../doppe-action-types/doppe-action-type-coupons-list/doppe-action-type-coupons-list';
import {
    doppeActionTypeLinksList
} from '../../../doppe-action-types/doppe-action-type-links-list/doppe-action-type-links-list';
import {doppeActionTypeOutfit} from '../../../doppe-action-types/doppe-action-type-outfit/doppe-action-type-outfit';
import {
    doppeActionTypeArticlesList
} from '../../../doppe-action-types/doppe-action-type-articles-list/doppe-action-type-articles-list';
import {
    doppeActionTypeServicesList
} from '../../../doppe-action-types/doppe-action-type-services-list/doppe-action-type-services-list';
import {
    doppeActionTypeVideosList
} from '../../../doppe-action-types/doppe-action-type-videos-list/doppe-action-type-videos-list';
import {
    doppeActionTypeEmbedIframe
} from '../../../doppe-action-types/doppe-action-type-embed-iframe/doppe-action-type-embed-iframe';
import {
    doppeActionTypeBlogPostViewer
} from '../../../doppe-action-types/doppe-action-type-blog-post/doppe-action-type-blog-post-viewer';
import {
    doppeActionTypeServiceViewer
} from '../../../doppe-action-types/doppe-action-type-service/doppe-action-type-service-viewer';
import {
    doppeActionTypeEventViewer
} from '../../../doppe-action-types/doppe-action-type-event/doppe-action-type-event-viewer';
import {
    doppeActionTypeContactFormViewerMetadata
} from '../../../doppe-action-types/doppe-action-type-contact-form/doppe-action-type-contact-form-viewer-metadata';
import {
    doppeActionTypeContactFormMetadata
} from '../../../doppe-action-types/doppe-action-type-contact-form/doppe-action-type-contact-form-metadata';
import {
    doppeActionTypeFollowViewer
} from '../../../doppe-action-types/doppe-action-type-follow/doppe-action-type-follow-viewer';
import {
    doppeActionTypeMembershipViewer
} from '../../../doppe-action-types/doppe-action-type-membership/doppe-action-type-membership-viewer';
import {
    doppeActionTypeProductsViewer
} from '../../../doppe-action-types/doppe-action-type-products/doppe-action-type-products-viewer';
import {
    doppeActionTypeProductViewer
} from '../../../doppe-action-types/doppe-action-type-product/doppe-action-type-product-viewer';
import {
    doppeActionTypePatreonViewer
} from '../../../doppe-action-types/doppe-action-type-patreon/doppe-action-type-patreon-viewer';
import {
    doppeActionTypeSubscribeEmbedViewer
} from '../../../doppe-action-types/doppe-action-type-embed/doppe-action-type-embed-viewer';
import {
    doppeActionTypeVideoViewer
} from '../../../doppe-action-types/doppe-action-type-video/doppe-action-type-video-viewer';
import {
    doppeActionTypeSubscribeMetaData
} from '../../../doppe-action-types/doppe-action-type-subscribe/doppe-action-type-subscribe-metadata';
import {
    doppeActionTypeSubscribeViewerMetadata
} from '../../../doppe-action-types/doppe-action-type-subscribe/doppe-action-type-subscribe-viewer-metadata';
import {
    doppeActionTypeLinkViewer
} from '../../../doppe-action-types/doppe-action-type-link/doppe-action-type-link-viewer';
import {
    doppeActionTypeDonateMetadata
} from '../../../doppe-action-types/doppe-action-type-donate/doppe-action-type-donate-metadata';
import {
    doppeActionTypeDonateViewerMetadata
} from '../../../doppe-action-types/doppe-action-type-donate/doppe-action-type-donate-viewer-metadata';
import {
    doppeActionTypeShareViewer
} from '../../../doppe-action-types/doppe-action-type-share/doppe-action-type-share-viewer';
import {
    doppeActionTypeSpeedDialViewer
} from '../../../doppe-action-types/doppe-action-type-speed-dial/doppe-action-type-speed-dial-viewer';
import {
    doppeActionTypeCouponViewer
} from '../../../doppe-action-types/doppe-action-type-coupon/doppe-action-type-coupon-viewer';
import {
    doppeActionTypeCouponsListViewer
} from '../../../doppe-action-types/doppe-action-type-coupons-list/doppe-action-type-coupons-list-viewer';
import {
    doppeActionTypeLinksListViewer
} from '../../../doppe-action-types/doppe-action-type-links-list/doppe-action-type-links-list-viewer';
import {
    doppeActionTypeOutfitViewer
} from '../../../doppe-action-types/doppe-action-type-outfit/doppe-action-type-outfit-viewer';
import {
    doppeActionTypeArticlesListViewer
} from '../../../doppe-action-types/doppe-action-type-articles-list/doppe-action-type-articles-list-viewer';
import {
    doppeActionTypeServicesListViewer
} from '../../../doppe-action-types/doppe-action-type-services-list/doppe-action-type-services-list-viewer';
import {
    doppeActionTypeVideosListViewer
} from '../../../doppe-action-types/doppe-action-type-videos-list/doppe-action-type-videos-list-viewer';
import {
    doppeActionTypeEmbedIframeViewer
} from '../../../doppe-action-types/doppe-action-type-embed-iframe/doppe-action-type-embed-iframe-viewer';
import {
    doppeActionTypeSocialIconViewer
} from '../../../doppe-action-types/doppe-action-type-social-icon/doppe-action-type-social-icon-viewer';
import {
    doppeActionTypeDebugApiViewer
} from '../../../doppe-action-types/doppe-action-type-debug-api/doppe-action-type-debug-api-viewer';
import {
    doppeActionTypeSkipViewer
} from '../../../doppe-action-types/doppe-action-type-skip/doppe-action-type-skip-viewer';
import {
    DoppeActionTypesMetadataStore,
    doppeActionTypesMetadataStoreCreate
} from '../../../client-server-common/doppe-action-types-metadata-store/doppe-action-types-metadata-store';
import {
    doppeActionTypeAutoBlogPost
} from '../../../doppe-action-types/doppe-action-type-auto-blog-post/doppe-action-type-auto-blog-post';
import {
    doppeActionTypeAutoBlogPostViewer
} from '../../../doppe-action-types/doppe-action-type-auto-blog-post/doppe-action-type-auto-blog-post-viewer';
import {arrayDistinct, asyncMapToArray, iterableMapToArray} from '@wix/devzai-utils-common';
import {BymoPageProps, BymoPagePropsForViewer, bymoPagePropsResolveAllActions,} from '../../../client-server-common';
import {BymoTemplatesIds} from '../../../client-server-common/doppe-templates/doppe-templates';
import {
    BymoTemplateSpeedDial,
    BymoTemplateSpeedDialSearchAppearance
} from '../../../components/bymo-templates/bymo-template-speed-dial/bymo-template-speed-dial.metadata';
import {
    doppeActionTypeDevMediaView
} from '../../../doppe-action-types/doppe-action-type-dev-media-view/doppe-action-type-dev-media-view';
import {
    doppeActionTypeDevMediaViewViewer
} from '../../../doppe-action-types/doppe-action-type-dev-media-view/doppe-action-type-dev-media-view-viewer';
import {
    doppeActionTypeEvents
} from '../../../doppe-action-types/doppe-action-type-events-list/doppe-action-type-events';
import {
    doppeActionTypeEventsViewer
} from '../../../doppe-action-types/doppe-action-type-events-list/doppe-action-type-events-viewer';
import {
    doppeActionTypeMembershipList
} from '../../../doppe-action-types/doppe-action-type-membership-list/doppe-action-type-membership-list';
import {
    doppeActionTypeMembershipListViewer
} from '../../../doppe-action-types/doppe-action-type-membership-list/doppe-action-type-membership-list-viewer';
import {
    doppeActionTypeDevModals
} from '../../../doppe-action-types/doppe-action-type-dev-modals/doppe-action-type-dev-modals';
import {
    doppeActionTypeDevModalsViewer
} from '../../../doppe-action-types/doppe-action-type-dev-modals/doppe-action-type-dev-modals-viewer';
import {
    doppeActionTypeRequests
} from '../../../doppe-action-types/doppe-action-type-requests/doppe-action-type-requests';
import {
    doppeActionTypeRequestsViewer
} from '../../../doppe-action-types/doppe-action-type-requests/doppe-action-type-requests-viewer';
import {doppeActionTypeRequest} from '../../../doppe-action-types/doppe-action-type-request/doppe-action-type-request';
import {
    doppeActionTypeRequestViewer
} from '../../../doppe-action-types/doppe-action-type-request/doppe-action-type-request-viewer';
import {
    doppeActionTypePaidMessage
} from '../../../doppe-action-types/doppe-action-type-paid-message/doppe-action-type-paid-message';
import {
    doppeActionTypePaidMessageViewer
} from '../../../doppe-action-types/doppe-action-type-paid-message/doppe-action-type-paid-message-viewer';
import {
    doppeActionTypeCalendly
} from '../../../doppe-action-types/doppe-action-type-calendly/doppe-action-type-calendly';
import {
    doppeActionTypeCalendlyViewer
} from '../../../doppe-action-types/doppe-action-type-calendly/doppe-action-type-calendly-viewer/doppe-action-type-calendly-viewer';
import {
    doppeActionTypeDevViewerStorybook
} from '../../../doppe-action-types/doppe-action-type-dev-viewer-storybook/doppe-action-type-dev-viewer-storybook';
import {
    doppeActionTypeDevViewerStorybookViewer
} from '../../../doppe-action-types/doppe-action-type-dev-viewer-storybook/doppe-action-type-dev-viewer-storybook-viewer';
import {doppeActionTypeIframe} from '../../../doppe-action-types/doppe-action-type-iframe/doppe-action-type-iframe';
import {
    doppeActionTypeIframeViewer
} from '../../../doppe-action-types/doppe-action-type-iframe/doppe-action-type-iframe-viewer/doppe-action-type-iframe-viewer';
import {
    doppeActionTypeLinkView
} from '../../../doppe-action-types/doppe-action-type-link-view/doppe-action-type-link-view';
import {
    doppeActionTypeLinkViewViewer
} from '../../../doppe-action-types/doppe-action-type-link-view/doppe-action-type-link-view-viewer';
import {
    doppeActionTypeEmbedPageList
} from '../../../doppe-action-types/doppe-action-type-embed-page-list/doppe-action-type-embed-page-list';
import {
    doppeActionTypeEmbedPageListViewer
} from '../../../doppe-action-types/doppe-action-type-embed-page-list/doppe-action-type-embed-page-list-viewer';
import {doppeActionTypeContent} from '../../../doppe-action-types/doppe-action-type-content/doppe-action-type-content';
import {
    doppeActionTypeContentViewer
} from '../../../doppe-action-types/doppe-action-type-content/doppe-action-type-content-viewer';
import {doppeActionTypeSpotify} from '../../../doppe-action-types/doppe-action-type-spotify/doppe-action-type-spotify';
import {
    doppeActionTypeSpotifyViewer
} from '../../../doppe-action-types/doppe-action-type-spotify/doppe-action-type-spotify-viewer/doppe-action-type-spotify-viewer';
import {
    doppeActionTypeSoundcloud
} from '../../../doppe-action-types/doppe-action-type-soundcloud/doppe-action-type-sound-cloud';
import {
    doppeActionTypeSoundcloudViewer
} from '../../../doppe-action-types/doppe-action-type-soundcloud/doppe-action-type-soundcloud-viewer/doppe-action-type-soundcloud-viewer';
import {
    doppeActionTypeAppleMusic
} from '../../../doppe-action-types/doppe-action-type-apple-music/doppe-action-type-apple-music';
import {
    doppeActionTypeAppleMusicViewer
} from '../../../doppe-action-types/doppe-action-type-apple-music/doppe-action-type-apple-music-viewer/doppe-action-type-apple-music-viewer';
import {
    doppeActionTypeTwitterEmbed
} from '../../../doppe-action-types/doppe-action-type-twitter-embed/doppe-action-type-twitter-embed';
import {
    doppeActionTypeTwitterEmbedViewer
} from '../../../doppe-action-types/doppe-action-type-twitter-embed/doppe-action-type-twitter-embed-viewer/doppe-action-type-twitter-embed-viewer';
import {
    doppeActionTypeYoutubeVideo
} from '../../../doppe-action-types/doppe-action-type-youtube-video/doppe-action-type-youtube-video';
import {
    doppeActionTypeYoutubeVideoViewer
} from '../../../doppe-action-types/doppe-action-type-youtube-video/doppe-action-type-youtube-video-viewer';
import {doppeActionTypeBanner} from '../../../doppe-action-types/doppe-action-type-banner/doppe-action-type-banner';
import {
    doppeActionTypeBannerViewer
} from '../../../doppe-action-types/doppe-action-type-banner/doppe-action-type-banner-viewer';
import {
    doppeActionTypeSendEmailViewer
} from '../../../doppe-action-types/doppe-action-type-send-email/doppe-action-type-send-email-viewer';
import {
    doppeActionTypeSendEmail
} from '../../../doppe-action-types/doppe-action-type-send-email/doppe-action-type-send-email';
import {
    doppeActionTypeContactCard
} from '../../../doppe-action-types/doppe-action-type-contact-card/doppe-action-type-contact-card';
import {
    doppeActionTypeContactCardViewer
} from '../../../doppe-action-types/doppe-action-type-contact-card/doppe-action-type-contact-card-viewer';
import {
    doppeActionTypeContactCardList
} from '../../../doppe-action-types/doppe-action-type-contact-card-list/doppe-action-type-contact-card-list';
import {
    doppeActionTypeContactCardListViewer
} from '../../../doppe-action-types/doppe-action-type-contact-card-list/doppe-action-type-contact-card-list-viewer';
import {
    doppeActionTypeSaveContact
} from '../../../doppe-action-types/doppe-action-type-save-contact/doppe-action-type-save-contact';
import {
    doppeActionTypeSaveContactViewer
} from '../../../doppe-action-types/doppe-action-type-save-contact/doppe-action-type-save-contact-viewer';
import {
    doppeActionTypeCallButton
} from '../../../doppe-action-types/doppe-action-type-call-button/doppe-action-type-call-button';
import {
    doppeActionTypeCallButtonViewer
} from '../../../doppe-action-types/doppe-action-type-call-button/doppe-action-type-call-button-viewer';
import {
    doppeActionTypeNavigateButton
} from '../../../doppe-action-types/doppe-action-type-navigate-button/doppe-action-type-navigate-button';
import {
    doppeActionTypeNavigateButtonViewer
} from '../../../doppe-action-types/doppe-action-type-navigate-button/doppe-action-type-navigate-button-viewer';
import {
    doppeActionTypeFbMessengerButton
} from '../../../doppe-action-types/doppe-action-type-fb-messenger-button/doppe-action-type-fb-messenger-button';
import {
    doppeActionTypeFbMessengerButtonViewer
} from '../../../doppe-action-types/doppe-action-type-fb-messenger-button/doppe-action-type-fb-messenger-button-viewer';
import {
    doppeActionTypeWhatsappButton
} from '../../../doppe-action-types/doppe-action-type-whatsapp-button/doppe-action-type-whatsapp-button';
import {
    doppeActionTypeWhatsappButtonViewer
} from '../../../doppe-action-types/doppe-action-type-whatsapp-button/doppe-action-type-whatsapp-button-viewer';
import {
    doppeActionTypeTelegramButton
} from '../../../doppe-action-types/doppe-action-type-telegram-button/doppe-action-type-telegram-button';
import {
    doppeActionTypeTelegramButtonViewer
} from '../../../doppe-action-types/doppe-action-type-telegram-button/doppe-action-type-telegram-button-viewer';
import {
    doppeActionTypeDevContent
} from '../../../doppe-action-types/doppe-action-type-dev-content/doppe-action-type-dev-content';
import {
    doppeActionTypeDevContentViewer
} from '../../../doppe-action-types/doppe-action-type-dev-content/doppe-action-type-dev-content-viewer';
import {
    doppeLockActionTypeCode
} from '../../../doppe-action-types/doppe-lock-action-type-code/doppe-lock-action-type-code';
import {
    doppeLockActionTypeCodeViewer
} from '../../../doppe-action-types/doppe-lock-action-type-code/doppe-lock-action-type-code-viewer';
import {
    doppeLockActionTypeVideo
} from '../../../doppe-action-types/doppe-lock-action-type-video/doppe-lock-action-type-video';
import {
    doppeLockActionTypeVideoViewer
} from '../../../doppe-action-types/doppe-lock-action-type-video/doppe-lock-action-type-video-viewer';
import {
    doppeLockActionTypeContactForm
} from '../../../doppe-action-types/doppe-lock-action-type-contact-form/doppe-lock-action-type-contact-form';
import {
    doppeLockActionTypeContactFormViewer
} from '../../../doppe-action-types/doppe-lock-action-type-contact-form/doppe-lock-action-type-contact-form-viewer';
import {
    doppeLockActionTypeSubscribe
} from '../../../doppe-action-types/doppe-lock-action-type-subscribe/doppe-lock-action-type-subscribe';
import {
    doppeLockActionTypeSubscribeViewer
} from '../../../doppe-action-types/doppe-lock-action-type-subscribe/doppe-lock-action-type-subscribe-viewer';

export class DoppeActionTypesStore {

    private actionTypesMetadataStore;

    constructor(actionTypesMetadataStore: DoppeActionTypesMetadataStore) {
        this.actionTypesMetadataStore = actionTypesMetadataStore;
    }

    private registeredActionTypes = new Map<DoppeActionType.Id, DoppeActionType<any>>();

    public getActionTypesMetadataStore() {
        return this.actionTypesMetadataStore;
    }

    public registerActionType<PROPS extends {} = {}>(
        actionTypeMetadata: DoppeActionTypeMetadata<PROPS>,
        actionTypeViewerMetadata: DoppeActionTypeViewerMetadata<PROPS>
    ) {
        const registeredMetadata = this.actionTypesMetadataStore.getActionTypeMetadataByActionTypeId(actionTypeMetadata.id);

        if (registeredMetadata !== actionTypeMetadata) {
            throw new Error(`Mismsatch between action type metadata for action type '${actionTypeMetadata.id}'`)
        }

        this.registeredActionTypes.set(actionTypeMetadata.id, {
            ...actionTypeMetadata,
            ...actionTypeViewerMetadata
        });
    }

    public getRegisteredActionTypes() {
        return [...this.registeredActionTypes.values()];
    }

    public getRegisteredActionType(actionTypeId: DoppeActionType.Id) {
        return this.registeredActionTypes.get(actionTypeId);
    }
}

export function doppeActionTypesStoreCreate() {

    const doppeActionTypesStore = new DoppeActionTypesStore(doppeActionTypesMetadataStoreCreate());

    doppeActionTypesStore.registerActionType(doppeActionTypeSaveContact, doppeActionTypeSaveContactViewer);
    doppeActionTypesStore.registerActionType(doppeActionTypeLink, doppeActionTypeLinkViewer);

    doppeActionTypesStore.registerActionType(doppeActionTypeSendEmail, doppeActionTypeSendEmailViewer);
    doppeActionTypesStore.registerActionType(doppeActionTypeLinkView, doppeActionTypeLinkViewViewer);
    doppeActionTypesStore.registerActionType(doppeActionTypeSubscribeMetaData, doppeActionTypeSubscribeViewerMetadata);
    doppeActionTypesStore.registerActionType(doppeActionTypeVideo, doppeActionTypeVideoViewer);
    doppeActionTypesStore.registerActionType(doppeActionTypeSubscribeEmbed, doppeActionTypeSubscribeEmbedViewer);
    doppeActionTypesStore.registerActionType(doppeActionTypePatreon, doppeActionTypePatreonViewer);
    doppeActionTypesStore.registerActionType(doppeActionTypeProduct, doppeActionTypeProductViewer);
    doppeActionTypesStore.registerActionType(doppeActionTypeProducts, doppeActionTypeProductsViewer);
    doppeActionTypesStore.registerActionType(doppeActionTypeMembership, doppeActionTypeMembershipViewer);
    doppeActionTypesStore.registerActionType(doppeActionTypeFollow, doppeActionTypeFollowViewer);
    doppeActionTypesStore.registerActionType(doppeActionTypeService, doppeActionTypeServiceViewer);
    doppeActionTypesStore.registerActionType(doppeActionTypeBlogPost, doppeActionTypeBlogPostViewer);
    doppeActionTypesStore.registerActionType(doppeActionTypeContactCard, doppeActionTypeContactCardViewer);
    doppeActionTypesStore.registerActionType(doppeActionTypeCallButton, doppeActionTypeCallButtonViewer);
    doppeActionTypesStore.registerActionType(doppeActionTypeNavigateButton, doppeActionTypeNavigateButtonViewer);
    doppeActionTypesStore.registerActionType(doppeActionTypeFbMessengerButton, doppeActionTypeFbMessengerButtonViewer);
    doppeActionTypesStore.registerActionType(doppeActionTypeWhatsappButton, doppeActionTypeWhatsappButtonViewer);
    doppeActionTypesStore.registerActionType(doppeActionTypeTelegramButton, doppeActionTypeTelegramButtonViewer);


    doppeActionTypesStore.registerActionType(doppeActionTypeContactCardList, doppeActionTypeContactCardListViewer);
    doppeActionTypesStore.registerActionType(doppeActionTypeAutoBlogPost, doppeActionTypeAutoBlogPostViewer);
    doppeActionTypesStore.registerActionType(doppeActionTypeEvent, doppeActionTypeEventViewer);
    doppeActionTypesStore.registerActionType(doppeActionTypeContactFormMetadata, doppeActionTypeContactFormViewerMetadata);
    doppeActionTypesStore.registerActionType(doppeActionTypeDonateMetadata, doppeActionTypeDonateViewerMetadata);
    doppeActionTypesStore.registerActionType(doppeActionTypeShare, doppeActionTypeShareViewer);
    doppeActionTypesStore.registerActionType(doppeActionTypeSpeedDial, doppeActionTypeSpeedDialViewer);
    doppeActionTypesStore.registerActionType(doppeActionTypeCoupon, doppeActionTypeCouponViewer);
    doppeActionTypesStore.registerActionType(doppeActionTypeCouponsList, doppeActionTypeCouponsListViewer);
    doppeActionTypesStore.registerActionType(doppeActionTypeLinksList, doppeActionTypeLinksListViewer);
    doppeActionTypesStore.registerActionType(doppeActionTypeOutfit, doppeActionTypeOutfitViewer);
    doppeActionTypesStore.registerActionType(doppeActionTypeArticlesList, doppeActionTypeArticlesListViewer);
    doppeActionTypesStore.registerActionType(doppeActionTypeServicesList, doppeActionTypeServicesListViewer);
    doppeActionTypesStore.registerActionType(doppeActionTypeVideosList, doppeActionTypeVideosListViewer);
    doppeActionTypesStore.registerActionType(doppeActionTypeEvents, doppeActionTypeEventsViewer);
    doppeActionTypesStore.registerActionType(doppeActionTypeRequests, doppeActionTypeRequestsViewer);
    doppeActionTypesStore.registerActionType(doppeActionTypeRequest, doppeActionTypeRequestViewer);
    doppeActionTypesStore.registerActionType(doppeActionTypePaidMessage, doppeActionTypePaidMessageViewer);

    doppeActionTypesStore.registerActionType(doppeActionTypeMembershipList, doppeActionTypeMembershipListViewer);
    doppeActionTypesStore.registerActionType(doppeActionTypeCalendly, doppeActionTypeCalendlyViewer);
    doppeActionTypesStore.registerActionType(doppeActionTypeIframe, doppeActionTypeIframeViewer);
    doppeActionTypesStore.registerActionType(doppeActionTypeEmbedPageList, doppeActionTypeEmbedPageListViewer);
    doppeActionTypesStore.registerActionType(doppeActionTypeContent, doppeActionTypeContentViewer);
    doppeActionTypesStore.registerActionType(doppeActionTypeSpotify, doppeActionTypeSpotifyViewer);
    doppeActionTypesStore.registerActionType(doppeActionTypeSoundcloud, doppeActionTypeSoundcloudViewer);
    doppeActionTypesStore.registerActionType(doppeActionTypeAppleMusic, doppeActionTypeAppleMusicViewer);
    doppeActionTypesStore.registerActionType(doppeActionTypeBanner, doppeActionTypeBannerViewer);


    doppeActionTypesStore.registerActionType(doppeActionTypeTwitterEmbed, doppeActionTypeTwitterEmbedViewer);
    doppeActionTypesStore.registerActionType(doppeActionTypeYoutubeVideo, doppeActionTypeYoutubeVideoViewer);
    doppeActionTypesStore.registerActionType(doppeActionTypeSocialIcon, doppeActionTypeSocialIconViewer);

    doppeActionTypesStore.registerActionType(doppeLockActionTypeCode, doppeLockActionTypeCodeViewer);

    doppeActionTypesStore.registerActionType(doppeLockActionTypeVideo, doppeLockActionTypeVideoViewer);
    doppeActionTypesStore.registerActionType(doppeLockActionTypeContactForm, doppeLockActionTypeContactFormViewer);
    doppeActionTypesStore.registerActionType(doppeLockActionTypeSubscribe, doppeLockActionTypeSubscribeViewer);


    doppeActionTypesStore.registerActionType(doppeActionTypeDebugApi, doppeActionTypeDebugApiViewer);
    doppeActionTypesStore.registerActionType(doppeActionTypeSkip, doppeActionTypeSkipViewer);
    doppeActionTypesStore.registerActionType(doppeActionTypeEmbedIframe, doppeActionTypeEmbedIframeViewer);
    doppeActionTypesStore.registerActionType(doppeActionTypeDevMediaView, doppeActionTypeDevMediaViewViewer);
    doppeActionTypesStore.registerActionType(doppeActionTypeDevModals, doppeActionTypeDevModalsViewer);
    doppeActionTypesStore.registerActionType(doppeActionTypeDevViewerStorybook, doppeActionTypeDevViewerStorybookViewer);
    doppeActionTypesStore.registerActionType(doppeActionTypeDevContent, doppeActionTypeDevContentViewer);

    return doppeActionTypesStore;
}

export async function doppeActionTypesStoreLoadUsedActionTypesViewerComponents(
    options: {
        bymoTemplateId: string;
        actionTypesStore: DoppeActionTypesStore;
        bymoPageProps: BymoPagePropsForViewer<any>;
    }
) {
    const {
        actionTypesStore,
        bymoPageProps,
        bymoTemplateId
    } = options;


    const pageActions = bymoPagePropsResolveAllActions(bymoPageProps);
    
    const actionTypes = arrayDistinct(iterableMapToArray(pageActions, action => action.actionTypeId))

    if (bymoTemplateId === BymoTemplatesIds.SpeedDial) {

        const fullBymoPageProps = bymoPageProps as BymoPageProps<BymoTemplateSpeedDial.TemplateProps>;

        if (fullBymoPageProps.searchAppearance !== BymoTemplateSpeedDialSearchAppearance.Hidden) {
            actionTypes.push(doppeActionTypeSpeedDial.id)
        }
    }


    return new Map(await asyncMapToArray(actionTypes, async (actionTypeId, skip) => {

        const actionType = actionTypesStore.getRegisteredActionType(actionTypeId);

        if (actionType) {
            const viewerComponents = await actionType.loadViewerComponents?.();

            if (viewerComponents) {
                return [actionTypeId, viewerComponents];
            }
        }

        return skip;
    }))
}

export async function doppeActionTypesStoreLoadAllActionTypesViewerComponents(
    actionTypesStore: DoppeActionTypesStore
) {
    return new Map(await asyncMapToArray(actionTypesStore.getRegisteredActionTypes(), async (actionType, skip) => {

        const viewerComponents = await actionType.loadViewerComponents?.();

        if (viewerComponents) {
            return [actionType.id, viewerComponents];
        }

        return skip;
    }))
}