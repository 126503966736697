import {doppeActionTypeDefineMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {
    DoppeHideableValue,
    doppeHideableValueCreateHidden
} from '../../client-server-common/types/doppe-hideable-value';
import {DoppeActionTypeOutfitViewerLang} from './doppe-action-type-outfit-viewer.lang';
import {DoppeActionTypeProduct} from '../doppe-action-type-product/doppe-action-type-product';
import {
    arrayFlatten,
    arrayRemoveNullValues,
    asArray,
    immerProduce,
    iterableMapToArray,
    PartiallyOptional
} from '@wix/devzai-utils-common';
import {WixMediaResource, wixMediaResourceReplaceExternalImage} from '@wix/devzai-common-wix';
import {
    DoppeActionLayout,
    DoppeActionListItemClickBehaviour,
    doppeExternalProductProviderResolveBusinessEntityReference,
    DoppeListActionSettings
} from '../../client-server-common';
import {
    doppeDtoActionGetDefaultSearchListSettingsProps,
    doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction
} from '../../client-server-common/types/doppe-dto-action';

export namespace DoppeActionTypeOutfit {

    export interface ProductSettings extends DoppeActionTypeProduct.ActionSettings {
        id: string;
        productTitle: string;
    }

    export interface ActionSettings extends
        DoppeListActionSettings,
        DoppeListActionSettings.WithItemClickBehaviour
    {
        outfitImage: WixMediaResource | null;
        outfitTitle: DoppeHideableValue<string>;
        products: ProductSettings[];
        itemShowPrice: boolean;
        previewShowTitle: boolean;
        previewShowDescription: boolean;
        previewShowPrice: boolean;
        previewShowCoupon: boolean;
        previewProductButtonText: string;
    }
}

export const doppeActionTypeOutfit = doppeActionTypeDefineMetadata<DoppeActionTypeOutfit.ActionSettings>({
    id: 'io.bymo.action/outfit',
    resolveActionSettings: action => {

        const {
            products = [],
            ...restSettings
        } = action.settings;

        return {
            products: products.map(product => {
                return {
                    productProvider: null,
                    productProviderId: null,
                    ribbon: doppeHideableValueCreateHidden('SALE'),
                    ...product as PartiallyOptional<DoppeActionTypeOutfit.ProductSettings, 'productProvider' | 'productProviderId' | 'ribbon'>
                }
            }) ?? [],
            outfitImage: null,
            outfitTitle: DoppeActionTypeOutfitViewerLang.defaultTexts.outfitTitle,
            layout: DoppeActionLayout.Grid,
            itemClickBehaviour: DoppeActionListItemClickBehaviour.PreviewItem,

            showButton: false,
            buttonText: 'Visit My Store',
            buttonUrl: 'https://www.website.com',

            itemShowDescription: true,
            itemShowTitle: true,
            itemShowImage: true,
            itemShowPrice: true,
            itemShowDate: false,
            itemShowTextOnImage: false,
            previewShowCoupon: true,
            previewShowPrice: true,
            previewShowDescription: true,
            previewShowTitle: true,
            previewProductButtonText: 'Buy Now',

            listSearchSettings: doppeDtoActionGetDefaultSearchListSettingsProps(),
            ...restSettings
        }
    },
    prepareForViewer: (actionSettings, options) => {
        doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction(actionSettings, actionSettings => {
            actionSettings.products.forEach(item => {
                item.productPageLink = '';
            });
        }, options);
    },
    isWidgetOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    isListItemLinkOpener: true,
    resolveUsedMedia: actionSettings => {
        const images = arrayFlatten(actionSettings.products.map(product => {
            return product.productImage ? asArray(product.productImage) : []
        }));

        return arrayRemoveNullValues([actionSettings.outfitImage, ...images]);
    },
    resolveMainMedia: actionSettings => {
        const images = arrayFlatten(actionSettings.products.map(product => {
            return product.productImage ? asArray(product.productImage) : []
        }));

        return actionSettings.outfitImage ?? images[0] ?? null;
    },
    resolveUsedBusinessEntities: actionSettings => {
        return iterableMapToArray(actionSettings.products, (product, skip) => {
            return product.productProvider !== null && product.productProviderId !== null ?
                doppeExternalProductProviderResolveBusinessEntityReference(
                    product.productProvider,
                    product.productProviderId
                ) : skip
        });
    },
    resolveListItemsCount: actionSettings => {
        return actionSettings.products.length;
    },
    replaceExternalImages: (actionSettings, externalImagesUrlToImageResourceMap) => {
        return immerProduce(actionSettings, actionSettings => {
            if (actionSettings.outfitImage) {
                actionSettings.outfitImage =
                    wixMediaResourceReplaceExternalImage(actionSettings.outfitImage, externalImagesUrlToImageResourceMap)
            }
        })
    },
    resolveListItems: action => {
        const actionSettings = action.settings;
        return actionSettings.products.map(item => {
            return {
                title: item.productTitle,
                action: action,
                couponCode: item.couponCode,
                description: item.description,
                image: item.productImage ? asArray(item.productImage)[0] : null,
                itemId: item.id,
                ribbon: item.ribbon,
                url: item.productPageLink
            }
        });
    },
    resolveListSearchSettings: actionSettings => {
        return actionSettings.listSearchSettings;
    },

});